import React from 'react';
import injectSheet from "react-jss";
import { connect } from 'react-redux';
import BoxTop, {LOGO_ENUMS} from './BoxTop';
import {STYLE_ENUMS} from "./Button";
import Button from "./Button";
import {setPayeesImported} from "../redux/actions";

const styles = {
  wrapper: {
    'border-radius': '6px',
    'box-shadow': '0 0 7px 0 rgba(0,0,0,0.4);'
  },
  bodyWrapper: {
    'padding-left': '15px',
    'padding-right': '15px',
    'padding-bottom': '22px'
  },
  table: {
    'border-collapse': 'collapse',
    'width': '100%',
    '& td':{
    
    },
    '& tr:not(:first-child)':{
      'height': '47px',
      
      '& input': {
        'border': '1px solid #979797',
        'border-radius': '2px',
        'height': '18px',
        'width': '18px',
        'background-color': '#FFFFFF'
      },
      
      '& i': {
        color: '#008580'
      }
    }
  },
  bottomButtonsWrapper: {
    display: 'flex',
    
    '& > div:first-child': {
      'flex-grow': 1
    }
  },
  
  importedWrapper: {
    'padding-left': '105px',
    'padding-bottom': '30px',
    'padding-top': '20px',
    
    '& i': {
      'color': '#008580'
    }
  },
  
  headerRow: {
    'background-color': '#DDDDDD',
    'height': '33px',
    'font-size': '16px',
    'font-weight': 'bold'
  },
  
  pageBoxes: {
    display: 'flex',
    
    '& div': {
      'width': '42px',
      'height': '42px',
      'border': '1px solid #979797',
      'font-size': '16px',
      'color': '#333333',
      'text-align': 'center',
      'line-height': '42px',
      
      '& span': {
        'vertical-align': 'middle',
        'line-height': '42px'
      }
    },
    '& > div:first-child':{
      'background-color': '#D8D8D8'
    },
    '& > div:not(:first-child)': {
      'margin-left': '-1px'
    }
  }
};

class PayeesBox extends React.Component {
  constructor(props){
    super(props);
    
    this.state = {
      showViewButton: false,
      showCloseButton: false,
      showBlueButton: true,
      showViewAndEdit: true,
      
      isImported: false,
      
      showBody: false,
      
      pageNumber: 1,
      numberOfPages: 1
    };
  }
  
  clickCloseButton = () => {
    let {classes} = this.props;
    let {isImported, numberImported, totalImported} = this.state;
    
    if(isImported){
      this.setState({
        body: (
          <div className={classes.importedWrapper}>
            <i className={`icon icon-circle-confirmation-solid`} /> {`${numberImported}/${totalImported} Payees imported`}
          </div>
        ),
        showViewButton: true,
        showBlueButton: false,
        showViewAndEdit: false,
        showCloseButton: false,
        showBody: true
      })
    } else {
      this.setState({
        showViewButton: false,
        showBlueButton: true,
        showViewAndEdit: true,
        showCloseButton: false,
        showBody: false
      })
    }
  };
  
  clickViewButton = () => {
    let {classes} = this.props;
    
    this.setState({
        body: (
          <div className={classes.bodyWrapper}>
            {this.getPayeesTable()}
            <div className={classes.bottomButtonsWrapper}>
              <div>
              
              </div>
              <div>
                <Button width={'173px'} text={"Close"} onClick={this.clickCloseButton} colorClass={STYLE_ENUMS.BLUE_INVERSE} />
              </div>
            </div>
          </div>
        ),
        showBlueButton:false,
        showViewAndEdit:false,
        showCloseButton:false,
        showViewButton:true,
        isImported: true,
        showBody: true
      },
      ()=>{
        this.setState({
          showCloseButton: true,
          showViewButton: false,
          showViewAndEdit: false,
          showBlueButton: false,
          showBody: true
        })
      })
  };
  
  import = () => {
    let {classes} = this.props;
    let {totalImported} = this.state;
    let {numberImported} = this.state;
    
    if(totalImported === undefined && numberImported === undefined){
      totalImported = numberImported = this.props.payees.length;
    }
    
    this.props.setPayeesImported();
    
    this.setState({
      numberImported,
      totalImported
    },()=>{
      let {numberImported, totalImported} = this.state;
      this.setState({
        body: (
          <div className={classes.importedWrapper}>
            <i className={`icon icon-circle-confirmation-solid`} /> {`${numberImported}/${totalImported} Payees imported`}
          </div>
        )
      }, ()=>{
        this.setState({
          showBlueButton:false,
          showViewAndEdit:false,
          showCloseButton:false,
          showViewButton:true,
          isImported: true,
          showBody: true
        })
      })
    })
  };
  
  getPayeeRows(payees){
    let { pageNumber } = this.state;
    let rows = [];
    
    let firstPayee = ( pageNumber - 1 ) * 9;
    let lastPayee = firstPayee + 9;
    
    for(let i = firstPayee; i < lastPayee; i++){
      let payee = payees[i];
      rows.push(
        <tr>
          <td>
            <input type="checkbox" value={payee.id} checked={true} disabled={(payee.imported)} />
          </td>
          <td>
            {payee.name}
          </td>
          <td>
            {payee.accountNumber}
          </td>
          <td>
            {(payee.imported)?null:"Edit"}
          </td>
          <td>
            {(payee.imported)?<span><i className={`icon icon-circle-confirmation`}/> Imported</span>:null}
          </td>
        </tr>
      )
    }
    
    return rows;
  }
  
  getPayeesTable = () => {
    let {classes, payees} = this.props;
    let totalSelected = payees.length;
    let numberSelected = payees.length;
    
    let table = (
      <table className={classes.table}>
        <col width="5%" /><col width="20%" /><col width="20%" /><col width="35%" /><col width="20%" />
        <tr className={classes.headerRow}>
          <td></td>
          <td>Name</td>
          <td>Payment information</td>
          <td />
          <td>Status</td>
        </tr>
        {this.getPayeeRows(payees)}
      </table>
    );
    
    this.setState({
      numberImported: numberSelected,
      totalImported: totalSelected
    });
    
    return table;
  };
  
  getPageNumberBoxes = (numberOfPages) => {
    let boxes = [];
    
    for(let i = 0; i < numberOfPages; i++){
      boxes.push(
        <div>
          <span>{i+1}</span>
        </div>
      )
    }
    
    return boxes;
  };
  
  clickViewAndEdit = () => {
    let {classes, payees} = this.props;
    
    let numberOfPages = payees.length % 9;
    
    this.setState({
        numberOfPages,
        pageNumber: 1
      },
      this.setState({
          body: (
            <div className={classes.bodyWrapper}>
              {this.getPayeesTable()}
              <div className={classes.bottomButtonsWrapper}>
                <div className={classes.pageBoxes}>
                  {numberOfPages > 1 &&
                  this.getPageNumberBoxes(numberOfPages)
                  }
                </div>
                <div>
                  <Button width={'173px'} text={"Close"} onClick={this.clickCloseButton} colorClass={STYLE_ENUMS.BLUE_INVERSE} />
                </div>
                <div>
                  <Button width={'173px'} text={"Import to HSBC"} onClick={this.import} colorClass={STYLE_ENUMS.BLUE} />
                </div>
              </div>
            </div>
          )
        }, this.setState({
          showBlueButton:false,
          showViewAndEdit:false,
          showCloseButton:true,
          showViewButton: false,
          showBody: true
        })
      )
    )
  };
  
  getBody = () => {
    return this.state.body
  };
  
  render(){
    let {classes, payees} = this.props;
    let {
      showViewButton,
      showCloseButton,
      showBlueButton,
      showViewAndEdit,
      showBody
    } = this.state;
    
    return(
      <div className={classes.wrapper}>
        <BoxTop
          header={`${payees.length} Payees`}
          subText={"These are people or companies who you send money to."}
          bottomText={"Andrew L, Branden S, K-MART +418 more"}
          
          showViewButton={showViewButton}
          showCloseButton={showCloseButton}
          showBlueButton={showBlueButton}
          buttonText={"Import to HSBC"}
          showViewAndEdit={showViewAndEdit}
          
          blueButtonOnClick={this.import}
          viewAndEditOnClick={this.clickViewAndEdit}
          viewButtonOnClick={this.clickViewButton}
          closeButtonOnClick={this.clickCloseButton}
          
          logo={LOGO_ENUMS.PAYEES} />
        <div>
          {showBody && this.getBody()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({payees}) => {
  return {
    payees
  }
};

const mapDispatchToProps = {
  setPayeesImported
};

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(PayeesBox));