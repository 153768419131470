import React from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import injectSheet from "react-jss";
import {authAndFetch} from '../redux/actions';
import Button from '../components/Button'

const styles = {
  wrapper: {
    'padding-top': '10px'
  },
  
  headingWrapper:{
    'padding-top': '50px',
    
    '& span':{
      'font-size': '38px',
      'font-weight': '300',
      'line-height': '50px'
    }
  },
  
  loginHeaderWrapper:{
    'padding-top': '36px',
    'display': 'table-cell',
    
    '& span': {
      'vertical-align': 'super',
      'font-size': '22px'
    },
    
    '& img': {
      'padding-top': '',
      'width': '40px'
    }
  },
  
  loginFieldsWrapper:{
    'padding-top': '15px',
    
    '& div': {
      'padding-top': '10px'
    },
    '& span': {
      'color': '#333333'
    },
    '& input': {
      'height': '35px',
      'width': '285px',
      'border': '1px solid #999999',
      'border-radius': '5px',
      'background-color': '#FFFFFF',
      'font-size': '16px',
      'padding-left': '14px'
    }
  },
  
  buttonWrapper: {
    'padding-top': '40px'
  }
};

class Login extends React.Component {
  constructor(){
    super();
    
    this.state = {
      username: '',
      password: ''
    }
  }
  
  handleLogin = () => {
    let {username, password} = this.state;
    this.props.authAndFetch(username, password, this.props.history);
  };
  
  handleUsernameChange = (e) => {
    this.setState({username: e.target.value});
  };
  
  handlePasswordChange = (e) => {
    this.setState({password: e.target.value});
  };
  
  render() {
    let {classes, history} = this.props;
    return (
      <div className={classes.wrapper}>
        <div className={classes.headingWrapper}>
          <span>Switching has never been easier.</span>
        </div>
        
        {/*RBC LOGON*/}
        <div className={classes.loginHeaderWrapper}>
          <span>RBC Login</span>
          <img alt="RBC Logo" src="/images/rbc-logo.png"/>
        </div>
        
        {/*LOGON FIELDS*/}
        <div className={classes.loginFieldsWrapper}>
          <div>
            <span>Account number or email</span>
          </div>
          <div>
            <input value={this.state.username} onChange={this.handleUsernameChange} />
          </div>
          <div>
            <span>Password</span>
          </div>
          <div>
            <input type="password" value={this.state.password} onChange={this.handlePasswordChange} />
          </div>
        </div>
        
        {/*Buttons*/}
        <div className={classes.buttonWrapper}>
          <Button onClick={this.handleLogin} text="Login" colorClass="red"/>
          <Button onClick={() => {
            history.push('/rbc')
          }} text="Cancel" colorClass="white"/>
        </div>
      </div>)
  }
}

const mapDispatchToProps = {
  authAndFetch
};

export default connect(null, mapDispatchToProps)(withRouter(injectSheet(styles)(Login)));
