import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import injectSheet from 'react-jss'
import history from './history';
import AUTH from './pages/Auth';
import OVERVIEW from './pages/Overview';

const styles = {
  headerBar: {
    'background-color': '#000000',
    'height': '40px',
    'width': '100%',
    'padding-top': '15px',
    'padding-left': '45.7px'
  },
  headerText: {
    'color': '#FFFFFF',
    'margin': '15px'
  }
};

const PAGES = {
  AUTH,
  OVERVIEW
};

export class Template extends React.Component {
  
  render(){
    const {classes} = this.props;
    
    return(
      <React.Fragment>
        <div className={classes.headerBar}>
          <span className={classes.headerText}>HSBC Switching</span>
        </div>
        <div>
          <Router history={history}>
            <Redirect exact from="/" to="/rbc" />
            <Route path="/rbc" component={PAGES.AUTH} />
            <Route path="/report" component={PAGES.OVERVIEW} />
          </Router>
        </div>
      </React.Fragment>
    )
  }
}

export default injectSheet(styles)(Template);