import React from 'react';
import injectSheet from "react-jss";
import { connect } from 'react-redux';
import BoxTop, {LOGO_ENUMS} from './BoxTop';

const styles = {
  wrapper: {
    'border-radius': '6px',
    'box-shadow': '0 0 7px 0 rgba(0,0,0,0.4);'
  }
};

class TemplatesBox extends React.Component {
  constructor(props){
    super(props);
    
    this.state = {
      showViewButton: false,
      showCloseButton: false,
      showBlueButton: true,
      showViewAndEdit: true,
      showBody: false
    }
  }
  
  getBody = () => {
  
  };
  
  render(){
    let {classes} = this.props;
    let {
      showViewButton,
      showCloseButton,
      showBlueButton,
      showViewAndEdit,
      showBody
    } = this.state;
  
    return(
      <div className={classes.wrapper}>
        <BoxTop
          header={"24 Payment templates"}
          subText={"These are automatic payments that have been set up."}
          bottomText={"Payments to Andrew L. Goods Produce LTD + 22 more"}

          showViewButton={showViewButton}
          showCloseButton={showCloseButton}
          showBlueButton={showBlueButton}
          buttonText={"Import to HSBC"}
          showViewAndEdit={showViewAndEdit}

          blueButtonOnClick={()=>{}}
          viewAndEditOnClick={()=>{}}
          viewButtonOnClick={()=>{}}
          closeButtonOnClick={()=>{}}

          logo={LOGO_ENUMS.TEMPLATES} />
        <div>
          {showBody && this.getBody()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(TemplatesBox));