import axios from 'axios';

export const ACTIONS = {
  PROCESS_STATE: 'PROCESS_STATE',
  PUT_PAYEES: 'PUT_PAYEES',
  DONE_FETCHING: 'DONE_FETCHING'
};

export function setPayeesImported() {
  return (dispatch, getState) => {
    let {payees} = getState();
    
    let importedPayees = payees.map( payee => {
      return {
        ...payee,
        imported: true
      }
    });
    
    dispatch({
      type: ACTIONS.PUT_PAYEES,
      payees: importedPayees
    })
  }
}

export function authAndFetch(username, password, history) {
  return (dispatch) => {
    
    axios.post('https://0wpvs0se2b.execute-api.us-west-2.amazonaws.com/dev/authorize',{
      username,
      password
    })
      .then( ({data}) => {
        console.log(data)
        
        dispatch({
          type: ACTIONS.PROCESS_STATE,
          data
        });
        
        dispatch({
          type: ACTIONS.DONE_FETCHING
        });
        
        history.push('/rbc/grant')
      })
      .catch(()=>{
        dispatch({
          type: ACTIONS.PROCESS_STATE,
          data: dummyData
        });
  
        dispatch({
          type: ACTIONS.DONE_FETCHING
        });
  
        setTimeout(()=>{
          history.push('/rbc/grant')
        }, 1200);
      })
  };
}

export const dummyData = {
  "status": "success",
  "payees": [
    {
      "id": "ecc37b45-24c0-436e-a5bd-9ca3f346c1b5",
      "name": "Connection Error",
      "accountNumber": "123-12345-123456789",
      "imported": false
    },
    {
      "id": "aa2596b4-9a34-407b-928e-1603b51cfc12",
      "name": "Andrew Ou",
      "accountNumber": "123-12345-123456789",
      "imported": false
    },
    {
      "id": "aa2596b4-9a34-407b-928e-1603b51cfc12",
      "name": "Branden Siegle",
      "accountNumber": "123-12345-123456789",
      "imported": false
    },
    {
      "id": "aa2596b4-9a34-407b-928e-1603b51cfc12",
      "name": "Nika Chkhartishvili\n",
      "accountNumber": "123-12345-123456789",
      "imported": false
    },
    {
      "id": "aa2596b4-9a34-407b-928e-1603b51cfc12",
      "name": "David Lui",
      "accountNumber": "123-12345-123456789",
      "imported": false
    },
    {
      "id": "aa2596b4-9a34-407b-928e-1603b51cfc12",
      "name": "Ryan Tam",
      "accountNumber": "123-12345-123456789",
      "imported": false
    },
    {
      "id": "aa2596b4-9a34-407b-928e-1603b51cfc12",
      "name": "Stuart Cahil",
      "accountNumber": "123-12345-123456789",
      "imported": false
    },
    {   "id": "d99b7c10-6891-5de7-a231-f4dace19be19",   "name": "Jon Thomas",   "accountNumber": 50568092167486960,   "imported": false },
    {   "id": "fd166604-6007-5ba4-b181-3530c0900b28",   "name": "Arthur Powell",   "accountNumber": 26662505863003480,   "imported": false },
    {   "id": "5293b56c-84ca-5796-9a08-6ab5f95042c4",   "name": "Jacob McGuire",   "accountNumber": 74542830656555230,   "imported": false },
    {   "id": "8d362902-9ba0-5256-862c-ea0ac52f7470",   "name": "Vincent Watkins",   "accountNumber": 10208186295293958,   "imported": false },
    {   "id": "e1e6bf5a-b145-5aeb-b582-c8bbed974778",   "name": "Christina Goodwin",   "accountNumber": 73007225227555660,   "imported": false },
    {   "id": "3a7dc6f8-4469-5577-b8c7-de3c57fc1531",   "name": "Bernice Malone",   "accountNumber": 41854936602041640,   "imported": false },
    {   "id": "48ba4b13-ae4c-5698-8fe6-ddbae011ca4d",   "name": "Terry Wise",   "accountNumber": 3132100775392115,   "imported": false },
    {   "id": "4852b196-ea9a-5da2-b180-a094f1355620",   "name": "Rena Bowen",   "accountNumber": 95987793687006060,   "imported": false },
    {   "id": "c14285f1-706b-507e-931a-25cc32ff1088",   "name": "Agnes Cortez",   "accountNumber": 7506810644625929,   "imported": false },
    {   "id": "ddf4d77e-7734-501d-9104-87691dd75853",   "name": "Phillip Hayes",   "accountNumber": 52735260625624260,   "imported": false },
    {   "id": "3f087c44-f75c-560f-84c7-e2f7decc3023",   "name": "Samuel Simon",   "accountNumber": 90386825579197090,   "imported": false },
    {   "id": "06c966ef-f6c2-5ab3-b1a5-e60f1512ef60",   "name": "Emma Lynch",   "accountNumber": 71553564186968430,   "imported": false },
    {   "id": "dcfea66b-155d-50f3-af33-e4838404bf91",   "name": "Lloyd Mullins",   "accountNumber": 21013953084651244,   "imported": false },
    {   "id": "ef5ff57d-bea8-5053-b9dc-25d8025c745d",   "name": "Eliza Harvey",   "accountNumber": 66469817465699730,   "imported": false },
    {   "id": "8ec65752-cabf-5f4c-a1a1-641c0ca76af5",   "name": "Donald Patrick",   "accountNumber": 2030261783765025,   "imported": false },
    {   "id": "4d435a6b-258e-56ff-b9ee-0307712e614a",   "name": "Carrie Allison",   "accountNumber": 41562265969732800,   "imported": false },
    {   "id": "74ed5c7e-596c-5d36-b90b-5f105e56df40",   "name": "Arthur Guerrero",   "accountNumber": 93393721641795740,   "imported": false },
    {   "id": "2196c8fa-ba7e-594a-8b3a-718efe2e821c",   "name": "Leona Leonard",   "accountNumber": 32557847224817260,   "imported": false },
    {   "id": "f28c8b59-71e3-5562-8ecf-b090e8c99026",   "name": "Johnny Carpenter",   "accountNumber": 58774126852529860,   "imported": false },
    {   "id": "4b730e06-670d-51f8-95f1-0791a0a4b468",   "name": "Dominic Gilbert",   "accountNumber": 12761317009683480,   "imported": false },
    {   "id": "ec1f644b-9a78-5257-8cf3-785be375b4d9",   "name": "Nathaniel Brock",   "accountNumber": 1626213972801132,   "imported": false },
    {   "id": "bc17b53c-a08b-569d-831a-2d86c939d357",   "name": "Alex Drake",   "accountNumber": 59776866483551590,   "imported": false },
    {   "id": "cb64bb4d-f909-575d-b39d-13a3db79f428",   "name": "Sarah Wagner",   "accountNumber": 10642375735684696,   "imported": false },
    {   "id": "33dade8e-a26c-567f-999b-70ea8f0dfbd5",   "name": "Steve Bowers",   "accountNumber": 75232988757687660,   "imported": false },
    {   "id": "2a584e8f-4f35-5108-ac64-7f975cca9ce2",   "name": "Eugenia Love",   "accountNumber": 44461603913613160,   "imported": false },
    {   "id": "062354f7-96c9-5200-9c8b-888c2b6837f5",   "name": "Chad Wheeler",   "accountNumber": 22414348118769830,   "imported": false },
    {   "id": "a0975896-60af-519a-933a-1642ca44d05d",   "name": "Helena Poole",   "accountNumber": 99398042429194060,   "imported": false },
    {   "id": "6470ed71-8f0b-5583-b4b0-d407155a3a27",   "name": "Mary Erickson",   "accountNumber": 46544372768686030,   "imported": false },
    {   "id": "171581b6-0f83-597d-8cb5-b7cb27469f40",   "name": "Jonathan Medina",   "accountNumber": 19887096000867548,   "imported": false },
    {   "id": "58ae8615-7653-5187-97a6-3890e622f301",   "name": "Keith Baker",   "accountNumber": 41304365617911730,   "imported": false },
    {   "id": "eac5eb19-39db-5606-b79a-1392e8901de3",   "name": "Ralph Martin",   "accountNumber": 28448464937303070,   "imported": false },
    {   "id": "a9e1d2ac-af29-553b-90d3-c42373674d37",   "name": "Gordon Hayes",   "accountNumber": 41773599668732800,   "imported": false },
    {   "id": "42cacb96-03d3-56bd-9241-570dd9243149",   "name": "Roy Thornton",   "accountNumber": 86753224497453580,   "imported": false },
    {   "id": "9d453b77-8eef-52ce-8985-da9062d1543d",   "name": "Timothy Massey",   "accountNumber": 65586343434068180,   "imported": false },
    {   "id": "72c070b6-07bf-598d-bac5-067cdbee98c3",   "name": "Ola Dunn",   "accountNumber": 91091822248093250,   "imported": false },
    {   "id": "862388f4-cd16-5014-8083-e4246da373b0",   "name": "Hettie Gill",   "accountNumber": 80230829725438600,   "imported": false },
    {   "id": "4d3a5ad0-2c87-583e-93e6-b2d54f3fc469",   "name": "Madge Steele",   "accountNumber": 14060116924405474,   "imported": false },
    {   "id": "aa8ff44f-9380-5124-b058-9d27c7adbc5b",   "name": "Victoria Austin",   "accountNumber": 52575165067570970,   "imported": false },
    {   "id": "1d7fd6ad-0a15-53d8-b638-fa06da291769",   "name": "Callie George",   "accountNumber": 22311046560225870,   "imported": false },
    {   "id": "2b56fa1a-f359-50ad-ae51-5ab06f9f39ab",   "name": "Lee Hunter",   "accountNumber": 28247417635585584,   "imported": false },
    {   "id": "6d1af83a-fe3f-591c-8ac5-356345f75d77",   "name": "Elijah Weaver",   "accountNumber": 1078683230436465,   "imported": false },
    {   "id": "8af17fa9-c2b6-544f-ade2-191d40e4b61f",   "name": "Manuel Lamb",   "accountNumber": 70119061425808800,   "imported": false },
    {   "id": "0a30b243-435a-5012-9132-d7a8e863aa57",   "name": "Ricardo Morales",   "accountNumber": 27870877347636696,   "imported": false },
    {   "id": "2c833daf-bcc4-5f10-9bb3-f2ee658dde58",   "name": "Caroline Wise",   "accountNumber": 7749628468019003,   "imported": false },
    {   "id": "e3a616fa-30ec-5aa2-8955-4c1bed75194e",   "name": "Herman Hughes",   "accountNumber": 52743268909639000,   "imported": false },
    {   "id": "c61f9414-fefc-5a65-ad23-3ba23fc28e29",   "name": "Cora Chavez",   "accountNumber": 43173742324841224,   "imported": false },
    {   "id": "9c83e61c-b300-511e-935d-87813c1762e6",   "name": "Maggie Boone",   "accountNumber": 86915068129754260,   "imported": false },
    {   "id": "5044d4d8-648d-5693-ba99-713668fa4110",   "name": "Mayme Hale",   "accountNumber": 51392810876760210,   "imported": false },
    {   "id": "c3343a94-cb11-5f62-96aa-5c3cb31968d8",   "name": "Willie Woods",   "accountNumber": 3123538524362125,   "imported": false },
    {   "id": "bd6d1691-f482-5044-befb-2e0a2f8ea672",   "name": "Devin Bryant",   "accountNumber": 67713456673924344,   "imported": false },
    {   "id": "3a76f0b6-2f54-5859-916d-4c8eb750a07f",   "name": "Pauline Greer",   "accountNumber": 66763616990776820,   "imported": false },
    {   "id": "5fe75783-a736-5ce9-ae1b-6158621c3163",   "name": "Micheal Miller",   "accountNumber": 99852477763481280,   "imported": false },
    {   "id": "02a1cbf6-6d75-57f5-a109-1964de19fe91",   "name": "Leon Porter",   "accountNumber": 39262151968780220,   "imported": false },
    {   "id": "2f424300-269d-539a-9298-a1ffa87a96e5",   "name": "Celia Clarke",   "accountNumber": 53444533597563260,   "imported": false },
    {   "id": "151558fb-e30c-5583-9b16-beb4dc81a360",   "name": "Lucy Ortega",   "accountNumber": 69588592015603230,   "imported": false },
    {   "id": "7db79116-d806-5934-a031-9926c4f631f2",   "name": "Albert Elliott",   "accountNumber": 56299767577478080,   "imported": false },
    {   "id": "bef37cd1-8672-565f-8a49-7a3627d8ec6d",   "name": "Phillip Moore",   "accountNumber": 94355809356512940,   "imported": false },
    {   "id": "ebfc5382-6482-5d32-bc02-102c332b34c1",   "name": "Henry Erickson",   "accountNumber": 15356236953382308,   "imported": false },
    {   "id": "f99244b9-243a-5b1a-ab57-414bf79dd6e9",   "name": "Gilbert Black",   "accountNumber": 7555736091572852,   "imported": false },
    {   "id": "a7655e3c-338b-51af-94f3-b50ef2d6e4a7",   "name": "Mitchell Pratt",   "accountNumber": 31575485998465556,   "imported": false },
    {   "id": "74cc9663-2a5a-5cf4-8a07-6bd4df534756",   "name": "Cameron Richards",   "accountNumber": 9454600260547286,   "imported": false },
    {   "id": "5af128d6-01fc-5893-992f-4b4ed66316f7",   "name": "Darrell Brady",   "accountNumber": 73402245726974660,   "imported": false },
    {   "id": "237a36b5-04a4-5990-a8aa-1c786ed20ebb",   "name": "Nicholas Cortez",   "accountNumber": 86251498289936860,   "imported": false },
    {   "id": "bfb78b55-8d76-5924-ac4d-ee7e9eb1024e",   "name": "Mattie Owens",   "accountNumber": 54665360408534270,   "imported": false },
    {   "id": "66b161e4-c646-579e-a859-a97447c31a36",   "name": "Helen Klein",   "accountNumber": 62330083020355060,   "imported": false },
    {   "id": "253b40a3-adb1-58b4-ba27-2f3c0f62e38a",   "name": "Kyle Becker",   "accountNumber": 2544852144445476,   "imported": false },
    {   "id": "9ce84843-9cd9-5a65-aedc-cc652f60b1ab",   "name": "Steve Holt",   "accountNumber": 29440423196975460,   "imported": false },
    {   "id": "b1f23ad1-0a14-5133-a59d-cb10c57445f6",   "name": "Myrtle Marshall",   "accountNumber": 71034463094039780,   "imported": false },
    {   "id": "20b92d03-cb62-5acd-95e7-a55773a8d344",   "name": "Christine Wise",   "accountNumber": 56570128785352860,   "imported": false },
    {   "id": "a6827598-2e1d-5886-8a02-b9c72321ac3b",   "name": "Rebecca Lyons",   "accountNumber": 37556977048610000,   "imported": false },
    {   "id": "409df603-94ba-5812-95d4-c744181dcb8f",   "name": "Edith Hansen",   "accountNumber": 71845741656237650,   "imported": false },
    {   "id": "ed13e71a-3f01-5543-bf61-c06f0ceab680",   "name": "Jimmy Powell",   "accountNumber": 75314385058124100,   "imported": false },
    {   "id": "993b70a7-90fb-5cf3-9537-3cc1cd540aca",   "name": "Marguerite Davis",   "accountNumber": 45451543672319760,   "imported": false },
    {   "id": "cbfc1b02-1af6-5eff-8bbc-9d4610505c58",   "name": "Winifred Bush",   "accountNumber": 85171918697266320,   "imported": false },
    {   "id": "04e987f3-6bb8-5a79-a3dc-d24a9bcfaae4",   "name": "Phillip Ray",   "accountNumber": 78263699316752240,   "imported": false },
    {   "id": "867c4373-b417-547d-9a89-6ccfed735383",   "name": "Cole Crawford",   "accountNumber": 30527779014479372,   "imported": false },
    {   "id": "03661e64-563e-5674-ad97-01183fcf0b7a",   "name": "Myrtle Mills",   "accountNumber": 56281878843960890,   "imported": false },
    {   "id": "a028d543-f56f-5322-9ea6-804f096dbd73",   "name": "Frederick Adams",   "accountNumber": 91831633569494140,   "imported": false },
    {   "id": "583c4e24-e67d-55f1-86f8-7c02d1bc23f7",   "name": "Sophia Cannon",   "accountNumber": 72252516901390130,   "imported": false },
    {   "id": "458e49bb-6d22-53a9-8814-8e45b6ef4c63",   "name": "Katie Peterson",   "accountNumber": 11468277170436888,   "imported": false },
    {   "id": "69d67483-3e2f-517c-899c-29967039e7ee",   "name": "Rachel Terry",   "accountNumber": 84969426379880060,   "imported": false },
    {   "id": "d999225d-9461-5799-966b-c7dfa6c6350a",   "name": "Lois McKenzie",   "accountNumber": 73555761730773040,   "imported": false },
    {   "id": "36554164-7ddd-5f51-b4a1-1b7222ca8120",   "name": "Gregory Romero",   "accountNumber": 33837568515713836,   "imported": false },
    {   "id": "a5ebebb1-4c3d-5cd6-b861-8108883ca402",   "name": "Juan Cummings",   "accountNumber": 27750823571874116,   "imported": false },
    {   "id": "24b37c45-e3d3-585a-9b23-3211605f9a04",   "name": "Leo Aguilar",   "accountNumber": 55068901919039240,   "imported": false },
    {   "id": "2fe60a16-cc5c-5c1b-8d24-b5a756c0122d",   "name": "Beulah Pierce",   "accountNumber": 8799744713046282,   "imported": false },
    {   "id": "5c90dce4-15ca-5d8d-8c4b-9356e5331e45",   "name": "Jeremiah Logan",   "accountNumber": 46599183597641460,   "imported": false },
    {   "id": "0eb5dcb6-4b70-56cc-a84b-6d088b27cd71",   "name": "Jared Ryan",   "accountNumber": 7786941207322974,   "imported": false },
    {   "id": "ebf870c3-d457-5c6a-8b6b-6953f547d48d",   "name": "Delia Rodriquez",   "accountNumber": 62064334101660230,   "imported": false },
    {   "id": "ed3d0b34-48be-56a5-8dbc-20b440dc0bda",   "name": "Linnie Rodriguez",   "accountNumber": 78743792286368690,   "imported": false },
    {   "id": "13d3751a-65ec-575b-8c47-8241e61cc16f",   "name": "Lois Park",   "accountNumber": 21255314876839810,   "imported": false },
    {   "id": "0c704d7e-f760-5172-8211-d4410a4ca503",   "name": "Christine Jefferson",   "accountNumber": 98927574485202000,   "imported": false },
    {   "id": "600121b2-7011-5b68-a0f0-34cc66ead150",   "name": "Jordan Hudson",   "accountNumber": 34809453406088496,   "imported": false },
    {   "id": "93e4280c-a471-543a-a0b8-cf4340e9aa11",   "name": "Hunter Hicks",   "accountNumber": 26631305906865772,   "imported": false },
    {   "id": "e5122450-8726-57bd-835d-de154ab67d09",   "name": "Philip Salazar",   "accountNumber": 58131083282588550,   "imported": false },
    {   "id": "8380e5ed-74a8-550c-b178-52b4230b16cd",   "name": "Allie Hunt",   "accountNumber": 5914914380256507,   "imported": false },
    {   "id": "13a07236-e2b9-5c3e-a4c1-803b064c7044",   "name": "Carl Brady",   "accountNumber": 52282924010423430,   "imported": false },
    {   "id": "30f017c4-8684-5404-ac4f-38f81e76ee84",   "name": "Essie Reynolds",   "accountNumber": 96392251148212600,   "imported": false },
    {   "id": "c982cd16-cbd4-59e6-b61e-328c1cff6f23",   "name": "Blake Crawford",   "accountNumber": 39924986597038100,   "imported": false },
    {   "id": "d30fadeb-9312-535e-a0e9-5ab1b6c67d92",   "name": "Ophelia Carter",   "accountNumber": 31060351675317524,   "imported": false },
    {   "id": "8a982186-e2f9-5c51-a0f1-daee021a2a8d",   "name": "Aiden Larson",   "accountNumber": 77647043582905890,   "imported": false },
    {   "id": "14126079-a509-5a08-84d9-870dbf0e8411",   "name": "Ophelia Nichols",   "accountNumber": 37500499503092024,   "imported": false },
    {   "id": "78112be9-5e3e-5122-8fbf-c7e62bca038e",   "name": "Milton Davidson",   "accountNumber": 51962437910111860,   "imported": false },
    {   "id": "ce3b6f39-ee53-59ae-81d2-83d348bcf898",   "name": "Landon Figueroa",   "accountNumber": 30660489965538840,   "imported": false },
    {   "id": "dac9f64d-c281-5706-a485-a2d61c645084",   "name": "Lillian Lynch",   "accountNumber": 45764006651482060,   "imported": false },
    {   "id": "375ee01d-f1f0-5aee-a1b1-99196b43b7cd",   "name": "Beatrice Fitzgerald",   "accountNumber": 22185078184023970,   "imported": false },
    {   "id": "94cfa86a-66ed-514f-bdbe-69586f4e16f5",   "name": "Oscar Moore",   "accountNumber": 68611726000547630,   "imported": false },
    {   "id": "6ceb5ee2-e6d8-5c07-9499-e879f322d2e1",   "name": "Luella Sanchez",   "accountNumber": 96587630349481100,   "imported": false },
    {   "id": "ac3346f8-daf2-5551-96b5-360527244832",   "name": "Gabriel Howell",   "accountNumber": 12740543036634620,   "imported": false },
    {   "id": "6c3ee986-309f-5282-bd37-2f381108392f",   "name": "Lenora Pratt",   "accountNumber": 52000726714580020,   "imported": false },
    {   "id": "2e99cf41-0a63-5fd0-81b7-4aebc4958719",   "name": "Charlie Brown",   "accountNumber": 84529132358613980,   "imported": false },
    {   "id": "33ee66f0-14b5-5d92-b127-f94d3c0f1196",   "name": "Blake Hamilton",   "accountNumber": 73037751552004820,   "imported": false },
    {   "id": "24f11872-3ea8-5bdf-9b25-25b7e3957cb9",   "name": "Eugenia Massey",   "accountNumber": 45289731898402660,   "imported": false },
    {   "id": "06d892c4-bfae-5a80-9eca-e136615b821b",   "name": "Gavin Castro",   "accountNumber": 98438514846354940,   "imported": false },
    {   "id": "54fe05a9-b530-56e6-a654-a95280d11b54",   "name": "Lulu Sanders",   "accountNumber": 81804920349620290,   "imported": false },
    {   "id": "2cd319c2-0659-5113-a970-ba49cc1a3341",   "name": "Lloyd Santiago",   "accountNumber": 99208005887171250,   "imported": false },
    {   "id": "9aa88cbc-0a4c-5810-a251-422f5e84d451",   "name": "Gerald Beck",   "accountNumber": 68421927326643150,   "imported": false },
    {   "id": "699832ab-2be0-5222-8313-5765df99ac76",   "name": "Joel Boyd",   "accountNumber": 77476617848712060,   "imported": false },
    {   "id": "600fc90e-baf8-5acf-8e2a-916b166bea9a",   "name": "Violet Fitzgerald",   "accountNumber": 93808753971099790,   "imported": false },
    {   "id": "f2363f21-65ea-5e47-be55-8449dc70f153",   "name": "Matthew Brewer",   "accountNumber": 19152018322327036,   "imported": false },
    {   "id": "9467d3d1-0886-5831-9079-669352a885e8",   "name": "Olga Aguilar",   "accountNumber": 45753816929545220,   "imported": false },
    {   "id": "83f1b3b6-f5e0-5b22-811b-1ce4540ed0e1",   "name": "Eliza Newton",   "accountNumber": 3827222520292731,   "imported": false },
    {   "id": "89f881f5-cced-58d1-afee-61fb205b6f2b",   "name": "Loretta Howell",   "accountNumber": 30511468166718350,   "imported": false },
    {   "id": "50b8b863-241f-56c8-9aac-1fbb59f611e8",   "name": "Laura Osborne",   "accountNumber": 25652122424920856,   "imported": false },
    {   "id": "08e3baf1-4e21-54fb-be9d-018171e4f8d9",   "name": "Maria Henderson",   "accountNumber": 93380808009995840,   "imported": false },
    {   "id": "cdc90276-017f-5e1d-ac21-94d0d35c3759",   "name": "Jessie Reid",   "accountNumber": 95655340790129730,   "imported": false },
    {   "id": "d84bda76-318e-51f5-aedc-7ce93cc2008d",   "name": "Fanny Lyons",   "accountNumber": 23143315011494252,   "imported": false },
    {   "id": "f4e97893-b7c9-5f3d-9476-5b89074ee716",   "name": "Vernon Reid",   "accountNumber": 28509369361547296,   "imported": false },
    {   "id": "e86a9a78-10cb-5bcf-98ea-c08e979bcf0e",   "name": "Adrian Rodriquez",   "accountNumber": 18533417197387880,   "imported": false },
    {   "id": "8ead5e98-dae8-5f60-b19a-c045306881a2",   "name": "Marie Maxwell",   "accountNumber": 57620767641173336,   "imported": false },
    {   "id": "ee47b461-038b-5a45-889a-c908298d9b9a",   "name": "Lillian Bridges",   "accountNumber": 61239153205856700,   "imported": false },
    {   "id": "97a637b8-ee1d-553a-850b-273d65fb0604",   "name": "Lawrence Hines",   "accountNumber": 915961805261048,   "imported": false },
    {   "id": "adbdb0a5-de7a-50f2-9743-1b3c9403535d",   "name": "Ora Malone",   "accountNumber": 28003685969754524,   "imported": false },
    {   "id": "6b6e8d1f-7ff7-5f65-9c87-fde8b8a69467",   "name": "Noah Cortez",   "accountNumber": 23632761487527770,   "imported": false },
    {   "id": "e328b051-4a5d-53d8-9431-612a548b7543",   "name": "Dale McGee",   "accountNumber": 53872240217610860,   "imported": false },
    {   "id": "2352ff6a-e6a1-5ad4-b1d1-ce71cd41bb8e",   "name": "Amanda Aguilar",   "accountNumber": 90258321958780690,   "imported": false },
    {   "id": "32ce9a5e-fcfa-53f3-8805-14a9790b2195",   "name": "Delia Castillo",   "accountNumber": 45941724834000070,   "imported": false },
    {   "id": "c45cef8a-f92c-50f3-b23e-1be957a73fdf",   "name": "Douglas Richardson",   "accountNumber": 85992522265008640,   "imported": false },
    {   "id": "1dc21932-ba44-5026-b7d5-ff5d4677bd1d",   "name": "Ernest Moran",   "accountNumber": 91162494437549950,   "imported": false },
    {   "id": "15cb631a-edc0-5885-b59a-3c11d38abc95",   "name": "Mitchell Summers",   "accountNumber": 23032994925463840,   "imported": false },
    {   "id": "bf5099f9-d3ae-52e7-a2a1-9fd221187d12",   "name": "Ian Jennings",   "accountNumber": 40976591476078150,   "imported": false },
    {   "id": "fc8f5b70-8cbb-5148-b8ff-0b9fc10082c5",   "name": "Maud Ramsey",   "accountNumber": 68144473960276540,   "imported": false },
    {   "id": "57229c39-bcb0-5594-9f70-282a24e2cbdb",   "name": "Iva Marshall",   "accountNumber": 66737122732888296,   "imported": false },
    {   "id": "d0c64724-7a29-56e4-a1f3-2cc8c903fa71",   "name": "Jim Allen",   "accountNumber": 88108682280334050,   "imported": false },
    {   "id": "eedc4602-3b12-5fe4-a46b-cefdc0b2b7f2",   "name": "Todd Vargas",   "accountNumber": 62821357255653780,   "imported": false },
    {   "id": "0bcc2d4a-c651-522a-ae0e-7aea5cb1b39b",   "name": "Dylan Peters",   "accountNumber": 89389899683161120,   "imported": false },
    {   "id": "3282311e-5b1e-5cf9-b2ff-26cee9603c9d",   "name": "Kenneth Fernandez",   "accountNumber": 31547056197099930,   "imported": false },
    {   "id": "7b3812d4-05c3-544e-bc34-34a15c4e162f",   "name": "Juan Brooks",   "accountNumber": 80695960936383490,   "imported": false },
    {   "id": "8162d660-aaef-520e-8aed-b3f92989ef4b",   "name": "Abbie Stanley",   "accountNumber": 96106442171607520,   "imported": false },
    {   "id": "2c3b438f-f530-5698-b3cd-6a073446723f",   "name": "Edith Chavez",   "accountNumber": 22424280082193356,   "imported": false },
    {   "id": "8aa28fe4-47f4-5e00-a80d-c24ad0b9983b",   "name": "Kate Buchanan",   "accountNumber": 30390752625395550,   "imported": false },
    {   "id": "18ea682a-5288-5435-bb54-d9c68dac4ea8",   "name": "Lucas Atkins",   "accountNumber": 35812360375986544,   "imported": false },
    {   "id": "a2c679f0-00b9-57a8-9412-e9f2a3257d5b",   "name": "Stephen Fuller",   "accountNumber": 51661513412648260,   "imported": false },
    {   "id": "4fe8e429-d6cb-599c-84ed-92d2dcb4940c",   "name": "Allen Santos",   "accountNumber": 67201909156292600,   "imported": false },
    {   "id": "4398d32b-7ae5-5b31-be36-d80bd69a0e83",   "name": "Cameron Pittman",   "accountNumber": 36318809631176770,   "imported": false },
    {   "id": "70d1725f-ca39-5f4a-adf7-23c94f7d460c",   "name": "Dustin McGuire",   "accountNumber": 82360125121211260,   "imported": false },
    {   "id": "10665d0b-a9a9-576c-b0d2-7842a4454f53",   "name": "Ola Morgan",   "accountNumber": 73370096872191000,   "imported": false },
    {   "id": "41d7b52d-447a-5568-ad0d-24d471523d6d",   "name": "Noah Mendez",   "accountNumber": 44683261735491410,   "imported": false },
    {   "id": "9d228bb8-2610-5230-9df4-c5fcfffd812b",   "name": "Daniel Greene",   "accountNumber": 12242246419731232,   "imported": false },
    {   "id": "b67370a3-83d4-5d08-a7c0-f076a8db9988",   "name": "Trevor Jacobs",   "accountNumber": 93232981189315260,   "imported": false },
    {   "id": "8d0ab8f8-66cd-5ae0-8fc8-ec0ed61ac871",   "name": "Michael Gill",   "accountNumber": 10000221167733340,   "imported": false },
    {   "id": "abd217b7-58ab-5bf5-b1a7-e2e71e45faa8",   "name": "Jennie Little",   "accountNumber": 24004772175376440,   "imported": false },
    {   "id": "1f59c4ee-0c39-523d-bb33-8fe08523837e",   "name": "Thomas Rogers",   "accountNumber": 92247963553721330,   "imported": false },
    {   "id": "cf42ccd6-8edc-5c6f-9069-79c97c8b4492",   "name": "Maude Manning",   "accountNumber": 11901721783922792,   "imported": false },
    {   "id": "864c5223-241c-54e6-bf00-589e8f813085",   "name": "Violet Todd",   "accountNumber": 78616080434821180,   "imported": false },
    {   "id": "518a37b8-733a-5163-8c9d-a435595317f0",   "name": "Henry Webster",   "accountNumber": 29635698353064468,   "imported": false },
    {   "id": "b0e67e0a-ed45-5f4a-875f-588932df98a0",   "name": "Jackson Carlson",   "accountNumber": 90545879738963400,   "imported": false },
    {   "id": "70bba5a4-47c3-5752-b1b7-ca858968bd0f",   "name": "Lilly Evans",   "accountNumber": 36548010550843520,   "imported": false },
    {   "id": "2d52bb45-8c10-56bd-968d-9fdafd4d4087",   "name": "Viola Johnson",   "accountNumber": 33511533425543110,   "imported": false },
    {   "id": "c80fc215-92a9-52d6-b26f-73d64074655e",   "name": "Roy Jensen",   "accountNumber": 35788996544447136,   "imported": false },
    {   "id": "50c63503-b645-58bb-be80-063fb91a41af",   "name": "Jane McGuire",   "accountNumber": 73952369578811620,   "imported": false },
    {   "id": "3bf885e9-cc1c-50c1-9c34-dc1a5f777caa",   "name": "Rodney Mitchell",   "accountNumber": 72845191091529740,   "imported": false },
    {   "id": "fe371b7d-46b6-5478-8607-3d6d3979c12b",   "name": "Nannie Hopkins",   "accountNumber": 99341807688814640,   "imported": false },
    {   "id": "9601c053-fc3c-57e5-9893-3dcc72e4868b",   "name": "Alejandro Roberson",   "accountNumber": 18111130778091720,   "imported": false },
    {   "id": "5db7b8c0-d160-5a8f-81ad-423cd29c1045",   "name": "Chester McGuire",   "accountNumber": 77493879511524350,   "imported": false },
    {   "id": "5bfa1855-8f42-566f-bb43-e9dca427bf11",   "name": "Larry Robinson",   "accountNumber": 26532248143651790,   "imported": false },
    {   "id": "4918079d-eadf-5e3a-87b5-7d621a94575f",   "name": "Harold Haynes",   "accountNumber": 61267831750666260,   "imported": false },
    {   "id": "6c7bb788-6023-59b1-9c49-2c8167ab5c08",   "name": "Alice Walters",   "accountNumber": 39076778142801940,   "imported": false },
    {   "id": "519d6746-87fc-5199-b5aa-1de042406380",   "name": "Lulu Powers",   "accountNumber": 67253304461531420,   "imported": false },
    {   "id": "723bc125-5bfb-52e3-8e4e-0aa4df7c6c2e",   "name": "Iva Thomas",   "accountNumber": 3239072244720708,   "imported": false },
    {   "id": "920cb7e7-10fd-58f3-bf92-a3bbc9a86d15",   "name": "Estelle Weaver",   "accountNumber": 58400735592396690,   "imported": false },
    {   "id": "1797fab8-2477-5745-ad21-e50ee69de66e",   "name": "Alejandro Tran",   "accountNumber": 15429137865239310,   "imported": false },
    {   "id": "20185f9c-b108-5b52-9c6d-b33cd84393d2",   "name": "Franklin Scott",   "accountNumber": 40090303045333840,   "imported": false },
    {   "id": "93e6127e-d4a6-5d3f-8bc0-9ad3c2f5dfdf",   "name": "Maurice Stokes",   "accountNumber": 46901173384085750,   "imported": false },
    {   "id": "1b83f6cb-41ac-5998-ae39-f2028e449919",   "name": "Mayme Hudson",   "accountNumber": 17229510115925328,   "imported": false },
    {   "id": "df4b410e-ca1b-536d-940a-43259359d53e",   "name": "Corey Thornton",   "accountNumber": 63300697447152610,   "imported": false },
    {   "id": "af8492ca-ef59-5d2b-ba95-94767ba11c3f",   "name": "Madge Martinez",   "accountNumber": 12203506619375964,   "imported": false },
    {   "id": "c8872e19-f572-52cf-957f-014a7e3cb7a3",   "name": "Brent Walker",   "accountNumber": 57451341481146290,   "imported": false },
    {   "id": "c60a621b-77b6-5e9d-83e8-1ca8ab3ef1d4",   "name": "Alma Daniel",   "accountNumber": 11677002394828772,   "imported": false },
    {   "id": "ae0b8868-33e7-5af5-b11f-e53a469962c2",   "name": "Arthur Burton",   "accountNumber": 9075253087412696,   "imported": false },
    {   "id": "bc82e0a7-88b2-55eb-8618-85bcb1205d40",   "name": "Vernon Olson",   "accountNumber": 91354157603302610,   "imported": false },
    {   "id": "3e6e8a3c-4e85-54ea-a8bd-de86841221c1",   "name": "Jean Roberts",   "accountNumber": 8717223010082973,   "imported": false },
    {   "id": "745d3205-ebf5-5ac9-98fc-5412699913ca",   "name": "Ina Banks",   "accountNumber": 9994754398526776,   "imported": false },
    {   "id": "ee55102c-7466-518b-8e4b-d2690c821273",   "name": "Timothy Brooks",   "accountNumber": 93583597092864260,   "imported": false },
    {   "id": "2691fcbc-e327-59df-9a4b-eb72180956f9",   "name": "Martin Wilson",   "accountNumber": 51368273253553580,   "imported": false },
    {   "id": "c38c06b4-ec12-5718-a7cd-080f5eb0ac52",   "name": "Evelyn Walker",   "accountNumber": 14543950166269912,   "imported": false },
    {   "id": "8ce38fe1-c970-5899-bf05-d2ed772bb793",   "name": "Ida Montgomery",   "accountNumber": 48781904944768350,   "imported": false },
    {   "id": "8b5061e1-b215-56e4-bf6e-40c46a5f0c8a",   "name": "Christopher Tate",   "accountNumber": 24234105432504844,   "imported": false },
    {   "id": "03130dc0-6757-59ce-aa06-dfe1e7087046",   "name": "Myrtle Massey",   "accountNumber": 16344386151326946,   "imported": false },
    {   "id": "feeec9a1-b885-57ab-976a-1fc17a95cc6c",   "name": "Dollie Cohen",   "accountNumber": 82245070873375220,   "imported": false },
    {   "id": "ce31d21f-ccfb-5167-bd51-29b626bee858",   "name": "Blanche Meyer",   "accountNumber": 85245012301942500,   "imported": false },
    {   "id": "e50fce60-b7fa-5412-b497-ee1ef23c09df",   "name": "Bruce Wells",   "accountNumber": 25497010218576948,   "imported": false },
    {   "id": "89297a4e-81de-5c08-80c7-99c515d2129e",   "name": "Alberta Robertson",   "accountNumber": 13838300446004768,   "imported": false },
    {   "id": "2f937006-7c97-5b3f-aca0-a766f56bc908",   "name": "Calvin Lynch",   "accountNumber": 91447339591184620,   "imported": false },
    {   "id": "daa0c4dc-61c2-587c-a832-131ffc03f924",   "name": "Douglas Haynes",   "accountNumber": 11973330475945976,   "imported": false },
    {   "id": "29edf3d4-746e-510d-be21-796f3b10df36",   "name": "Cynthia Hicks",   "accountNumber": 40523679069054100,   "imported": false },
    {   "id": "ac922453-b335-5a5a-ba6b-7c72b7d73108",   "name": "Marguerite Haynes",   "accountNumber": 94877827640923570,   "imported": false },
    {   "id": "ebabce42-3caa-5ab0-8e57-110badaf9e25",   "name": "Benjamin Craig",   "accountNumber": 73146697029225230,   "imported": false },
    {   "id": "c4a454db-c3f3-51cb-af93-ccee80b98f68",   "name": "Mario Austin",   "accountNumber": 48444797128101480,   "imported": false },
    {   "id": "72ff93a8-019b-52f3-9d89-a7fdcf08bec2",   "name": "Adele Ellis",   "accountNumber": 52423705575793860,   "imported": false },
    {   "id": "4ca3859f-b1cd-56da-af7c-167899f68194",   "name": "Josie Simmons",   "accountNumber": 50663527921381930,   "imported": false },
    {   "id": "fa88bd2d-79c0-583c-b74d-bccaa8022cd3",   "name": "Beatrice Wilkins",   "accountNumber": 32768267230980616,   "imported": false },
    {   "id": "09fa9ae4-8050-52a7-8ea8-cf3ddedf4047",   "name": "Anne Hodges",   "accountNumber": 46418436279799864,   "imported": false },
    {   "id": "0070c01f-5f1a-53d7-821d-c2d5312873d2",   "name": "Alberta Wade",   "accountNumber": 51743383202742560,   "imported": false },
    {   "id": "58f60103-89be-5de8-9da4-fe1773941d61",   "name": "George Jensen",   "accountNumber": 23829001860131760,   "imported": false },
    {   "id": "f5397878-f31d-5ee3-91c5-b2811d510f61",   "name": "Joseph Cruz",   "accountNumber": 34666417528165570,   "imported": false },
    {   "id": "93c88531-6ebe-58a6-bdb4-47ba23c9d159",   "name": "David Ball",   "accountNumber": 46212037539126390,   "imported": false },
    {   "id": "e8c653e8-939e-5ae0-af55-a708b7a0de70",   "name": "Dominic Lee",   "accountNumber": 88302605006861810,   "imported": false },
    {   "id": "b36ebc65-8aab-57f5-b515-b342efd89752",   "name": "Jack Francis",   "accountNumber": 38670701877749860,   "imported": false },
    {   "id": "d134c137-1f86-5fe8-9ecb-924a304bd0dc",   "name": "Sarah Arnold",   "accountNumber": 29239077598274664,   "imported": false },
    {   "id": "9c6d95b9-39f0-5d4d-96e5-36453054a7a1",   "name": "Lucille Bridges",   "accountNumber": 34336806493818708,   "imported": false },
    {   "id": "c5c6a9f0-6d63-53da-b80b-c1f07c49140f",   "name": "Ray Glover",   "accountNumber": 46775995822841000,   "imported": false },
    {   "id": "273d6233-7746-5e26-a018-e62fc9b82a35",   "name": "Joel Cruz",   "accountNumber": 90198266082267170,   "imported": false },
    {   "id": "d0e8b256-3afd-5abd-bfbd-f536b632d0e8",   "name": "Billy Schultz",   "accountNumber": 63532423074166696,   "imported": false },
    {   "id": "b23589e6-5f87-59b1-ba59-398267aa1191",   "name": "Eleanor Hall",   "accountNumber": 87174932195347780,   "imported": false },
    {   "id": "464a9db1-b3f3-5400-8ef7-e1966a27596d",   "name": "Luke Gill",   "accountNumber": 12601794532582860,   "imported": false },
    {   "id": "02867f83-612f-5064-bff9-1ed9c78358e1",   "name": "Michael Luna",   "accountNumber": 53711916719268970,   "imported": false },
    {   "id": "29499b0b-3462-5440-a80e-325e589ae7e8",   "name": "Nina Dean",   "accountNumber": 13418824577898848,   "imported": false },
    {   "id": "a6497a58-4c8c-50a6-819d-23ff53737796",   "name": "Mollie Graham",   "accountNumber": 2484871585519936,   "imported": false },
    {   "id": "31612524-3dde-5bf9-be4c-d0918c2b7b36",   "name": "Peter Hamilton",   "accountNumber": 48785971216742300,   "imported": false },
    {   "id": "7146123a-e971-5f13-a723-5e70c3cafffc",   "name": "Harriett Patterson",   "accountNumber": 62460893465173500,   "imported": false }
  ],
  "paymentTemplates": [
    {
      "id": "6245c1a6-5569-4e08-afa4-e2b54eb93872",
      "name": "Template1",
      "date": "2019/07/28",
      "imported": true,
      "payees": [
        {
          "id": "ecc37b45-24c0-436e-a5bd-9ca3f346c1b5",
          "name": "Branden Siegle",
          "accountNumber": "123-12345-123456789",
          "imported": true
        }
      ]
    }
  ],
  "payers": [
    {
      "id": "110ebc1d-91ea-4a56-a7b7-f1cc779d101c",
      "name": "Payer Name",
      "migrated": false,
      "email": "email@gmail.com"
    },
    {
      "id": "5db034e5-503a-4234-b86f-44188a10199b",
      "name": "Payer Name 2",
      "migrated": false,
      "email": "email@gmail.com"
    }
  ]
};