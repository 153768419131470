import React from 'react';
import { withRouter } from 'react-router-dom';
import injectSheet from "react-jss";
import Button from '../components/Button'

const styles = {
  wrapper: {
    'padding-top': '10px'
  },
  
  
  fiIconsWrapper: {
    'margin-left': '-15px',
    'padding-top': '10px',
    display: 'flex'
  },
  rbcIcon: {
    '& img':{
      width: '80px'
    }
  },
  arrows: {
    display: 'table-cell',
    color: '#333333',
    '& i':{
      'padding-top': '10px',
      'font-size': '56px'
    }
  },
  hsbcIcon: {
    '& img':{
      'padding-left': '10px',
      'padding-top': '17px',
      width: '160px'
    }
  },
  
  
  disclosure: {
    'padding-top': '15px',
    '& ul': {
      'padding-top': '10px'
    },
    '& li': {
      'line-height': '25px'
    }
  },
  
  
  buttonWrapper: {
    'padding-top': '50px'
  }
};

const GrantPermission = ({classes, history}) => (
  <div className={classes.wrapper}>
    <h1>Grant Permission</h1>
    
    {/*FI Icons*/}
    <div className={classes.fiIconsWrapper}>
      <div className={classes.rbcIcon}>
        <img alt="RBC Logo" src="/images/rbc-logo.png" />
      </div>
      <div className={classes.arrows}>
        <i className="icon icon-transfers" />
      </div>
      <div className={classes.hsbcIcon}>
        <img alt="HSBC Logo" src="/images/hsbc-logo.png" />
      </div>
    </div>
    
    {/*Paragraph*/}
    <div className={classes.disclosure}>
      <p>Allow HSBCnet to collect information about your bank.</p>
      <p>
        <ul>
          <li>Payee Information - Name and account number</li>
          <li>Payment Templates - Payees, amount, and frequency</li>
          <li>Payers - Transactional data</li>
        </ul>
      </p>
    </div>
    
    {/*Buttons*/}
    <div className={classes.buttonWrapper}>
      <Button onClick={()=>{history.push('/report')}} text="Allow" colorClass="red" />
      <Button onClick={()=>{history.push('/rbc')}} text="Cancel" colorClass="white" />
    </div>
  </div>
);

export default withRouter(injectSheet(styles)(GrantPermission));
