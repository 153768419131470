import {createStore, applyMiddleware} from 'redux';
import { ACTIONS, dummyData } from './actions';

import thunk from 'redux-thunk';

const initialState = {
    isFetched: false,
    numberOfItems: 0,
    payees: dummyData.payees,
    paymentTemplates: [],
    payers: []
};

let defaultReducer = (state = initialState, action) => {
  switch(action.type) {
    case ACTIONS.DONE_FETCHING:
      return state;
    case ACTIONS.PROCESS_STATE:
      let { payees, payers, paymentTemplates } = action.data;
      let numberOfItems = 0;
      
      numberOfItems = payees.length + payers.length + paymentTemplates.length;
      
      return {
        ...state,
        isFetched: true,
        numberOfItems,
        payees,
        payers,
        paymentTemplates
      };
      
    case ACTIONS.PUT_PAYEES:
      return {
        ...state,
        payees: action.payees
      };
      
    default:
      return state;
  }
};

/*let rootReducer = combineReducers({
  main: defaultReducer
});*/

export default function configureStore() {
  return createStore(
    defaultReducer,
    initialState,
    applyMiddleware(thunk)
  );
}