import React from 'react';
import injectSheet from "react-jss";
import Button, { STYLE_ENUMS } from '../components/Button';

export const LOGO_ENUMS = {
  PAYEES: {
    url: '/images/payee.png',
    alt: 'Payees Image'
  },
  TEMPLATES: {
    url: '/images/template.png',
    alt: 'Templates Image'
  },
  PAYERS: {
    url: '/images/payer.png',
    alt: 'Payers Image'
  }
};

const styles = {
  reportBoxWrapper: {
    'height': '121px'
  },
  topOfBox: {
    'display': 'flex'
  },
  imageWrapper: {
    'padding-left': '21px',
    'padding-top': '19px',
    '& img': {
      'width': '60px'
    }
  },
  descriptionWrapper: {
    'flex-grow': '1',
    'padding-top': '27px',
    'padding-left': '28px'
  },
  header: {
    'font-size': '22px'
  },
  subText: {
    'font-size': '14px',
    'line-height': '21px'
  },
  bottomText: {
    'padding-top': '11px',
    'font-size': '14px',
    'line-height': '21px'
  },
  
  viewAndEditWrapper: {
    'padding-top': '39px'
  },
  blueButtonWrapper: {
    'padding-right': '20px',
    'padding-top': '39px'
  }
};

class CollapsedReport extends React.Component{
  constructor(props){
    super(props);
    
    this.state = {
      showViewAndEdit: props.viewAndEditButton,
      showBlueButton: true,
      showViewButton: false
    }
  }
  
  render() {
    let {
      classes,
      logo,
      header,
      subText,
      bottomText,
      buttonText,
      
      blueButtonOnClick,
      viewAndEditOnClick,
      viewButtonOnClick,
      closeButtonOnClick,
      
      showViewButton,
      showCloseButton,
      showBlueButton,
      showViewAndEdit
    } = this.props;
    
    
    return (
      <div className={classes.reportBoxWrapper}>
        <div className={classes.topOfBox}>
          <div className={classes.imageWrapper}>
            <img alt={logo.alt} src={logo.url}/>
          </div>
          <div className={classes.descriptionWrapper}>
            <div className={classes.header}>{header}</div>
            <div className={classes.subText}>{subText}</div>
            <div className={classes.bottomText}>{bottomText}</div>
          </div>
          <div className={classes.viewAndEditWrapper}>
            {showViewAndEdit &&
            <Button width={'150px'} text="View and edit" onClick={viewAndEditOnClick} colorClass={STYLE_ENUMS.BLUE_INVERSE}/>
            }
          </div>
          <div className={classes.blueButtonWrapper}>
            {showBlueButton &&
            <Button width={'173px'} text={buttonText} onClick={blueButtonOnClick} colorClass={STYLE_ENUMS.BLUE} />
            }
            {showViewButton &&
            <Button width={'150px'} text="View" onClick={viewButtonOnClick} colorClass={STYLE_ENUMS.BLUE_INVERSE} />
            }
            {showCloseButton &&
            <Button width={'150px'} text="Close" onClick={closeButtonOnClick} colorClass={STYLE_ENUMS.BLUE_INVERSE} />
            }
          </div>
        </div>
      </div>
    )
  }
  
};

export default injectSheet(styles)(CollapsedReport);