import React from 'react';
import injectSheet from "react-jss";
import { connect } from 'react-redux';
import BoxTop, {LOGO_ENUMS} from './BoxTop';

const styles = {
  wrapper: {
    'border-radius': '6px',
    'box-shadow': '0 0 7px 0 rgba(0,0,0,0.4);'
  }
};

class PayersBox extends React.Component {
  constructor(props){
    super(props);
    
    this.state = {
      showViewButton: false,
      showCloseButton: false,
      showBlueButton: true,
      showViewAndEdit: false,
      showBody: false
    }
  }
  
  getBody = () => {
  
  };
  
  render(){
    let {classes} = this.props;
    let {
      showViewButton,
      showCloseButton,
      showBlueButton,
      showViewAndEdit,
      showBody
    } = this.state;
  
    return(
      <div className={classes.wrapper}>
        <BoxTop
          header={"50 Payers"}
          subText={"These are people or companies who pay you on a regular basis."}
          bottomText={"Royce Accounting LTD. +49 others"}

          showViewButton={showViewButton}
          showCloseButton={showCloseButton}
          showBlueButton={showBlueButton}
          buttonText={"Set up notifications"}
          showViewAndEdit={showViewAndEdit}

          blueButtonOnClick={()=>{}}
          viewAndEditOnClick={()=>{}}
          viewButtonOnClick={()=>{}}
          closeButtonOnClick={()=>{}}

          logo={LOGO_ENUMS.PAYERS} />
        <div>
          {showBody && this.getBody()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(PayersBox));