import React from 'react';
import injectSheet from "react-jss";
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import PayeesBox from '../components/PayeesBox';
import PayersBox from '../components/PayersBox';
import TemplatesBox from '../components/TemplatesBox';

const styles = {
  pageWrapper: {
    'padding-top': '25px',
    display: 'flex'
  },
  centerWrapper: {
    width: '90%'
  },
  logoWrapper:{
    '& img': {
      'width': '110px'
    }
  },
  topHeadingWrapper:{
    'padding-top': '40px',
    
    '& span': {
      'font-size': '22px',
      'color': '#333333',
      'line-height': '27px'
    },
    '& img': {
      width: '35px'
    }
  },
  secondHeadingWrapper:{
    'padding-top': '20px',
    
    '& span': {
      'line-height': '47px',
      'font-weight': '100',
      'font-size': '38px',
      'color': '#333333',
      'font-family': 'HSBCUnivers-Lt',
      
      '& span': {
        'font-weight': 'bold'
      }
    }
  },
  lastSyncedWrapper:{
    'padding-top': '30px',
    display: 'flex',
  },
  lastSyncedText:{
    'font-size': '14px',
    'line-height': '21px',
    'color': '#666666'
  },
  syncAgainWrapper:{
    'padding-left': '15px'
  },
  syncAgainText:{
    color: '#29678E'
  },
  payeesBox:{
    'padding-top': '25px',
  
  },
  templatesBox:{
    'padding-top': '18px',
  
  },
  payersBox:{
    'padding-top': '18px',
  
  },
  buffer: {
    'width': '5%'
  }
};

export class Overview extends React.Component {
  
  render(){
    let { classes, history, numberOfItems } = this.props;
    
    return(
      <div className={classes.pageWrapper}>
        <div className={classes.buffer} />
        <div className={classes.centerWrapper}>
          {/*HSBC LOGO*/}
          <div className={classes.logoWrapper} onClick={()=>{history.push('/rbc')}}>
            <img alt="HSBC Logo" src="/images/hsbc-logo.png" />
          </div>
          {/*After looking...*/}
          <div className={classes.topHeadingWrapper}>
            <span>After looking at your RBC account</span><img alt="RBC Logo" src="/images/rbc-logo.png" />
          </div>
          
          {/*There are ###...*/}
          <div className={classes.secondHeadingWrapper}>
            <span>There are <span className={classes.numberOfThings}>{numberOfItems+71}</span> things we can help you move</span>
          </div>
          
          {/*Last synced...*/}
          <div className={classes.lastSyncedWrapper}>
            <div>
              <span className={classes.lastSyncedText}>Account was last synced July 28, 2019 at 10:42am PST</span>
            </div>
            <div className={classes.syncAgainWrapper}>
              <span className={classes.syncAgainText}>Sync again</span>
            </div>
          </div>
          
          {/*Payees Box*/}
          <div className={classes.payeesBox}>
            <PayeesBox/>
          </div>
          
          {/*Templates Box*/}
          <div className={classes.templatesBox}>
            <TemplatesBox/>
          </div>
          
          {/*Payers Box*/}
          <div className={classes.payersBox}>
            <PayersBox/>
          </div>
        </div>
        <div className={classes.buffer} />
      </div>
    )
  }
}

export default connect(
  (state)=>{
    return{
      numberOfItems: state.numberOfItems
    }
  }, null)(withRouter(injectSheet(styles)(Overview)));