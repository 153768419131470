import React from 'react';
import { Switch, Route } from "react-router-dom";
import injectSheet from "react-jss";
import Footer from '../components/Footer';
import GrantPermission from './GrantPermission';
import Login from './Login';

const styles = {
  wrapper: {
    'width': '100%',
    'display': 'flex'
  },
  bufferLeft: {
    'width': '15%'
  },
  bufferRight: {
    'width': '20%'
  },
  balloonWrapper: {
    'background-image': 'url("/images/balloons.jpg")',
    'background-repeat': 'no-repeat',
    'background-position': 'right top',
    'height': '576px',
    'width': '40%'
  },
  authWrapper: {
    'display': 'flex'
  },
  authPageWrapper: {
  'height': '545px'
  }
};

export class Auth extends React.Component {
  render(){
    const {classes} = this.props;
    
    return(
      <React.Fragment>
        <div className={classes.wrapper}>
          <div className={classes.balloonWrapper} />
          <div className={classes.authWrapper}>
            <div className={classes.bufferLeft} />
            <div>
              <div className={classes.authPageWrapper}>
                <Switch>
                  <Route exact path="/rbc" component={Login} />
                  <Route exact path="/rbc/grant" component={GrantPermission} />
                </Switch>
              </div>
              <Footer />
            </div>
            <div className={classes.bufferRight} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default injectSheet(styles)(Auth);