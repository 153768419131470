import React from 'react';
import injectSheet from "react-jss";

export const STYLE_ENUMS = {
  RED: 'red',
  WHITE: 'white',
  BLUE: 'blue',
  BLUE_INVERSE: 'blueInverse'
}

const styles = {
  button: {
    'display': 'table-cell',
    'position': 'relative',
    'height': '47px',
    'width': '121px',
    'text-align': 'center',
    'vertical-align': 'middle',
    'font-size': '16px'
  },
  
  red: {
    'background-color': '#DB0011',
    'color': '#FFFFFF'
  },
  
  white: {
    'color': '#333333'
  },
  
  blue: {
    'background-color': '#4D6474',
    'color': '#FFFFFF',
  },
  
  blueInverse: {
    'background-color': '#FFFFFF',
    'color': '#29678E',
  }
};

const Button = ({classes, text, onClick, colorClass, width}) => {
  
  return (
    <div style={(width)? {width}: null} className={`${classes.button} ${classes[colorClass]}`} onClick={onClick}>
      {text}
    </div>
  )
};

export default injectSheet(styles)(Button);
